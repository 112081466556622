import { Tooltip } from 'react-tooltip';

export const Info = ({ children }) => {
  const id = crypto.randomUUID();

  return <div className='text-xs'>
    <div
      data-tooltip-id={`my-tooltip-${id}`}
      data-tooltip-content={children || ''}
      data-tooltip-place="right"
      data-tooltip-offset={5}
    >
      <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512" className="w-5 stroke-neutral-500">
        <path d="M248 64C146.39 64 64 146.39 64 248s82.39 184 184 184 184-82.39 184-184S349.61 64 248 64z" fill="none" stroke-miterlimit="10" stroke-width="32" />
        <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M220 220h32v116" />
        <path fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32" d="M208 340h88" />
        <path className="fill-neutral-500" d="M248 130a26 26 0 1026 26 26 26 0 00-26-26z" />
      </svg>
    </div>
    <Tooltip id={`my-tooltip-${id}`} style={{ borderRadius: 10 }} />
  </div>;
};