import { useState, useEffect } from 'react';

const useFormat = (format = "(NNN) NNN-NNNN", initialValue = "") => {
  const [value, setValue] = useState(initialValue);
  const [formatArray, setFormatArray] = useState([]);

  useEffect(() => {
    let tempArray = [];
    for (let i = 0; i < format.length; i++) {
      if (format[i] === '\\') {
        if (['N', 'A', 'X'].includes(format[i + 1])) {
          tempArray.push(format[++i]);
        }
      } else {
        tempArray.push(format[i]);
      }
    }
    setFormatArray(tempArray);
  }, [format]);

  const isMatch = (input, format) => {
    if (format === 'N') return /[0-9]/.test(input);
    if (format === 'A') return /[a-zA-Z]/.test(input);
    if (format === 'X') return /[a-zA-Z0-9]/.test(input);
    return true;
  };

  const generateFormattedString = (str) => {
    let formatted = '';
    let j = 0;
    let i = 0;

    while(i < formatArray.length && j < str.length) {
      if (['N', 'A', 'X'].includes(formatArray[i])) {
        if (isMatch(str[j], formatArray[i])) {
          formatted += str[j++];
        }
        else {
          break;
        }
        i++;
      }
      else if (formatArray[i] === str[j]) {
        formatted += str[j++];
        i++;

      } else {
        formatted += formatArray[i++];
      }
    }

    return formatted;
  };

  const handleChange = (str) => {
    setValue(generateFormattedString(str));
  }

  return [value, handleChange];
};

export default useFormat;