const Loading = () => {
  return <div className='flex justify-center items-center'>
    <div className='animate-spin h-8 w-8'>
    <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512" className="stroke-white"><path d="M288 193s12.18-6-32-6a80 80 0 1080 80" fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="28"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="28" d="M256 149l40 40-40 40"/><path d="M256 64C150 64 64 150 64 256s86 192 192 192 192-86 192-192S362 64 256 64z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"/></svg>
    </div>
  </div>;
};

export const Button = ({ label, onClick, disabled, hidden, selected, color, loading}) => {
  if (color === 'primary')
    return <div
      className={`flex justify-center items-center p-3 rounded-lg bg-blue-600 w-full cursor-pointer transition duration-300 ${disabled || loading ? 'opacity-50 cursor-default' : 'hover:ring-4 ring-blue-200'} ${hidden ? 'invisible' : ''} drop-shadow-lg`}
      onClick={disabled || loading ? () => { } : onClick}
    >
      <div className='text-md text-white font-bold select-none'>
        {loading ? <Loading /> : label}
      </div>
    </div>;

  return <div
    className={
      `flex justify-center items-center p-3 rounded-full ${!selected ? 'bg-neutral-200' : 'bg-blue-200 border border-2 border-blue-500'} w-full cursor-pointer transition duration-300 ${disabled || loading ? 'opacity-50 cursor-default' : 'hover:ring-4 ring-blue-200'} ${hidden ? 'invisible' : ''}`
    }
    onClick={disabled || loading ?  () => { } : onClick}
  >
    <div className='text-md text-black font-bold select-none'>
      {loading ? <Loading /> : label}
    </div>
  </div>;
};