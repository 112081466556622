import { Info } from "../Info";

export const Card = ({ children, title, prompt = true, subtitle, tooltip }) => {
  return <div
    className={`flex flex-col justify-center items-center w-80 bg-white p-6 rounded-xl space-y-3 drop-shadow`} //duration-1000 transition ease-out transform ${mounted ? 'translate-y-0 opacity-100' : 'translate-y-16 opacity-0'}`}
  >
    <div className={`flex space-x-2 item-center ${prompt ? '' : ' self-start'}`}>
      <div className={`${prompt ? 'font-black text-md' : 'text-sm font-bold text-right self-start'} whitespace-normal`}>
        {title || ''}
      </div>
      {tooltip && <Info>
        {tooltip}
      </Info>}
    </div>
    {subtitle && <div className='text-xs text-neutral-500'>{subtitle}</div>}
    {children}
  </div>;
};